import React, { useState, useEffect } from 'react';
import { RiCloseFill } from 'react-icons/ri';
import InputField from '../UI/InputField';
import TextArea from '../UI/TextArea';

function UpdateModal({ isOpen, onClose, onSubmit }) {
  const [currentTime, setCurrentTime] = useState(new Date());
  const [formData, setFormData] = useState({
    amountPayableBy: '',
    amountOwned: '',
    amountPaid: '',
    availableBalance: '',
    comment: ''
  });

  useEffect(() => {
    const timer = setInterval(() => setCurrentTime(new Date()), 1000);
    return () => clearInterval(timer);
  }, []);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(formData);
  };

  if (!isOpen) return null;

  return (
    <div className='overlay'>
      <RiCloseFill className='close-btn pointer' onClick={onClose} />
      <div className="modal-box max-w-800">
        <div className="p-40">
          <div className="flex justify-between items-center mb-4">
            <h3 className="bold-text">Add an Item</h3>
            <p className="text-lg text-gray-500">Time:
              {currentTime.toLocaleTimeString()}
            </p>
          </div>
          <form onSubmit={handleSubmit} className="m-t-20">
            <InputField
              label="Amount Payable By"
              name="amountPayableBy"
              value={formData.amountPayableBy}
              onChange={handleChange}
              required
            />
            <InputField
              label="Amount Owned"
              name="amountOwned"
              value={formData.amountOwned}
              onChange={handleChange}
              required
            />
            <InputField
              label="Amount Paid"
              name="amountPaid"
              value={formData.amountPaid}
              onChange={handleChange}
              required
            />
            <InputField
              label="Available Balance"
              name="availableBalance"
              value={formData.availableBalance}
              onChange={handleChange}
              required
            />
            <TextArea
              label="Comment"
              name="comment"
              value={formData.comment}
              onChange={handleChange}
            />
            <button 
              type="submit" 
              className="btn m-t-20 w-100"
            >
              Update Record
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default UpdateModal;